import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { Dialog, DialogCloseCallback } from '@app-types/Dialog';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  viewContainerRef: ViewContainerRef;
  animationDuration = 230;

  registerViewContainer(vcf: ViewContainerRef): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    this.viewContainerRef = vcf;
  }

  open<T>(type: Type<unknown>, data?: unknown, options?: { threshold: number }): Promise<T> {
    return new Promise(resolve => {
      if (!this.viewContainerRef) {
        return;
      }

      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(type);
      const ref = componentRef.instance as Dialog;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      ref.state = 'open';
      ref.data = data;

      const close = (complete: DialogCloseCallback) => {
        ref.state = 'close';

        setTimeout(() => {
          this.viewContainerRef.remove();
          complete();
        }, this.animationDuration);
      };

      setTimeout(() => {
        ref.resolve = (_data: T) => {
          close(() => {
            resolve(_data);
          });
        };

        ref.resolve();
      }, options?.threshold ?? 3000);
    });
  }
}
